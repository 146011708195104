<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 mt-2">
        <!-- <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5> -->
        <form role="form" @submit.prevent="handleSubmit" class="text-start">
          <div class="mb-3">
            <input id="email" type="email" class="form-control" placeholder="Email" v-model="email" required />
          </div>
          <div class="mb-3">
            <input id="password" type="password" class="form-control" placeholder="Password" v-model="password"
              required />
          </div>

          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="rememberMe" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              Remember me
            </label>
          </div>

          <div class="error text-sm text-danger">{{ error }}</div>

          <div class="text-center">
            <argon-button color="success" variant="gradient" full-width class="my-4 mb-2">
              <span v-if="isSigningIn">
                Signing in... <i class="fa fa-circle-o-notch fa-spin"></i>
              </span>
              <span v-else>
                Sign in
              </span>
            </argon-button>
          </div>
        </form>
        <!-- <div class="card border-0 mb-0">
          <div class="card-header bg-transparent">
            
          </div>
          <div class="card-body px-lg-5 pt-0">
            
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import useLogin from '@/composables/useLogin'
import useLogout from '@/composables/useLogout'
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from 'vue-router'
import moment from 'moment';
import { projectFirestore } from '../../firebase/config';
import axios from 'axios';
import { useStore } from "vuex";
import Api from '../../views/admin/Api';


export default {
  name:'Login',
  components: {
    ArgonButton,
  },
  setup(props, context) {
    // refs

    document.title = 'Modal Support';
    const isSigningIn = ref(false)
    const email = ref('')
    const password = ref('')
    const router = useRouter();
    const rememberMe = ref(false)
    const swal = inject('$swal')
    const store = useStore()
    const { error, login, user } = useLogin()
    const { logout } = useLogout();

    const handleLogin = async () => {

    }

    const handleSubmit = async () => {

      isSigningIn.value = true
      await login(email.value, password.value)
      if (!error.value) {
        //context.emit('login')
        let timeStamp = null;
        if (rememberMe.value == true) {
          timeStamp = moment().add(30, 'days').valueOf();
        } else {
          timeStamp = moment().add(5, 'hours').valueOf();
        }
        // console.log('user', user)
        await projectFirestore.collection('Users').doc(user.value).get().then(async(doc) => {
          if (doc.exists) {
            let chat_enabled = doc.data().chat_enabled ? doc.data().chat_enabled : false
            let payload = {
              email:email.value,
              user_id:user.value
            }
            // console.log('doc.data()', doc.data())
            if(doc?.data()?.user_type) {
              localStorage.setItem('user_type', doc?.data()?.user_type)
            }
            await Api.login(payload).then((res) => {
              if(res?.data?.token) {
                localStorage.setItem('inbox-chat-token', res.data.token)
                localStorage.setItem('token-time', moment.utc().valueOf().toString())
              }
            })
            async function handleLibrary(res, doc, payload, chat_enabled) {
              if (res.exists && res.data()?.parent_lib_id) {
                const parent_lib_id = res.data().parent_lib_id;
                const libraryRefKey = res.data().library_refkey;
                const libraryDomain = res.data().library_domain;

                 const updateLocalStorage = async (libraryDomain) => {
                  
                  await Api.getPermissions({ref_uid:user.value, role: doc?.data()?.user_type}).then((res) => {
                    if(res?.data?.permissions) {
                      res?.data?.permissions.forEach((el) => {
                        if(store.state.userPermissions[el.slug] !== undefined) {
                          store.state.userPermissions[el.slug] = el.is_enabled
                        }
                      })
                    }
                    console.log(' store.state.userPermissions',   store.state.userPermissions)
                  }).catch((err) => {
                    store.state.showToast = {
                      title:'Error',
                      description:'An error occcured while getting Permissions',
                      icon:'fa fa-exclamation-triangle',
                      time:moment().valueOf()
                    }
                  })

                  localStorage.setItem("support_Lib_id", doc.data().support_lib_id);
                  store.state.support_lib_id = doc.data().support_lib_id
                  localStorage.setItem("chat_enabled", chat_enabled);
                  localStorage.setItem("library_domain", libraryDomain);
                  if(res?.data()?.settings?.settings?.companyName) {
                    localStorage.setItem("company_name", res?.data()?.settings?.settings?.companyName);
                  }
                  localStorage.setItem("parent_lib_id", parent_lib_id);
                  localStorage.setItem('user_email', payload.email);
                  localStorage.setItem('user_id', payload.user_id);
                  store.state.user_id = payload.user_id
                  store.state.parent_lib_id = parent_lib_id;
                };

                const navigateUser = async () => {
                  if (doc?.data()?.user_type === 'agent') {
                    router.push({ name: 'ChatInboxClone' });
                  } else {
                    router.push({ name: 'Content' });
                  }
                };

                try {
                  if (libraryRefKey !== '') {
                    const refKeyResponse = await axios.get(`https://auth.locationapi.co/resources1?k=${libraryRefKey}`);
                    if (refKeyResponse?.data?.e === 1) {
                      await updateLocalStorage(libraryDomain);
                      await navigateUser();
                      return;
                    }
                  }
                  
                  if (libraryDomain !== '') {
                    const domainResponse = await axios.get(`https://auth.locationapi.co/resources1?d=${libraryDomain}`);
                    if (domainResponse?.data?.e === 1) {
                      await updateLocalStorage(libraryDomain);
                      await navigateUser();
                      return;
                    }
                  }

                  swal({
                    title: "Attention!",
                    text: "It looks like your subscription is no longer active. Please reach out to the team in Slack if you feel that this is a mistake or if you want to re-activate your account.",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Close",
                    customClass: {
                      confirmButton: "btn bg-gradient-danger",
                      cancelButton: "btn bg-gradient-danger",
                    },
                    buttonsStyling: false,
                  });
                  isSigningIn.value = false;
                  logout();
                } catch (error) {
                  console.error("Error fetching library info: ", error);
                  swal({
                    title: "Error",
                    text: "An error occurred while processing your request. Please try again later.",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Close",
                    customClass: {
                      confirmButton: "btn bg-gradient-danger",
                      cancelButton: "btn bg-gradient-danger",
                    },
                    buttonsStyling: false,
                  });
                  isSigningIn.value = false;
                  logout();
                }
              } else {
                localStorage.setItem("support_Lib_id", doc.data().support_lib_id);
                localStorage.setItem("chat_enabled", chat_enabled);
                localStorage.setItem("library_domain", res.data().library_domain);
                localStorage.setItem('user_email', payload.email);
                localStorage.setItem('user_id', payload.user_id);
                if (doc?.data()?.user_type === 'agent') {
                  router.push({ name: 'ChatInboxClone' });
                } else {
                  router.push({ name: 'Content' });
                }
              }
            }

            // Usage
            await projectFirestore.collection('SupportLibraries').doc(doc.data().support_lib_id).get().then(async (res) => {
              await handleLibrary(res, doc, payload, chat_enabled);
            });

          }
        })
      }
      isSigningIn.value = false



    }

    return { email, password, handleSubmit, error, rememberMe, isSigningIn }
  },
}
</script>