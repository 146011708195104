<template>
  <!-- New Main Navigation -->
  <!-- 
    :class="{
      'collapsed-left': isMenuCollapsed,
      'collapsed-right': isDetailCollapsed,
    }" 
  -->
  <div class="container-fluid ps-0 modal-chat-inbox">
    <div class="row">
      <div class="p-0 d-flex flex-column justify-content-between bg-gray-dark text-center app-main-nav" 
        :class="[{'top-45-menu': (($store.state.is_away == 1 && $store.state.show_alert) || ($store.state.isAgentProfileNotCompleted && $store.state.showAgentProfileAlert))}]">
        <div class="">
          <div class="list-group nav-icons">
            <!-- brand logo -->
            <div class="position-relative">
              <a href="javascript:void(0)" class="list-group-item list-group-item-action bg-transparent py-2 border-0 p-0 brand-logo-inbox-chat" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-trigger="hover" @click="toggleMenuCollapse">
                <svg viewBox="0 0 513 421" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-lg">
                  <path id="SymbolLogo" d="M486.367 5.66518C470.401 -2.0562 451.334 -0.0486403 437.383 10.7613L256.331 151.754L74.9685 10.298C60.8626 -0.357495 41.9513 -2.0562 26.1402 5.66518C10.4841 13.541 0.408447 29.6015 0.408447 47.2062V373.821C0.408447 391.58 10.4841 407.64 26.6052 415.516C33.1157 418.605 40.0912 420.149 46.9116 420.149C57.1423 420.149 67.218 416.752 75.5886 410.266L220.058 301.703C208.123 291.511 168.905 260.471 141.623 238.697L98.0651 274.06V149.746L175.88 210.9L254.781 275.605L255.091 275.296L437.228 410.266C451.179 421.23 470.246 423.238 486.212 415.516C502.178 407.795 512.408 391.58 512.408 373.821V47.2062C512.408 29.447 502.333 13.3866 486.367 5.66518ZM414.752 276.995L336.781 213.37L414.752 149.901V276.995Z" fill="currentColor" />
                </svg>
                
              </a>
            </div>
            <!-- <div data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Dashboard" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'ChatInbox' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-regular fa-table-layout text-sm"></i>
              </router-link>
            </div> -->

            <div data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Inbox" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'ChatInboxClone', query :{tab:'inbox'} }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-solid fa-message-lines text-sm"></i>
                <span v-if="$store?.state?.queueMembers?.length > 0" class="plus unread-conversations" :class="{ 'hundred-plus': ($store.state.queueMembers.length + $store.state.tickets?.filter((el) => el?.unread_messages_count)?.reduce((acc, ticket) => acc + ticket.unread_messages_count, 0)) > 99 }">
                  <!-- {{ $store.state.queueMembers.length + $store.state.tickets?.filter((el) => el?.unread_messages_count)?.reduce((acc, ticket) => acc + ticket.unread_messages_count, 0) }} -->
                  {{ $store.state.queueMembers.length  }}  
                </span>
              </router-link>

              


            </div>
            <div v-if="$store.state?.userPermissions.canCreateAndUpdateHelpCenterArticles" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Articles" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Content' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-solid fa-book-open-reader text-sm"></i>
                <!-- <i class="fa-regular fa-table-layout text-sm"></i> -->
                <!-- <i class="fa-solid fa-message-lines text-sm"></i> -->
              </router-link>
            </div>
            <!-- <div data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Inbox" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'ChatInbox' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-regular fa-table-layout text-sm"></i>
                 <i class="fa-solid fa-inbox text-sm"></i> 
                <i class="fa-solid fa-inbox text-sm"></i> 
                <span class="unread-conversations">0</span> 
              </router-link>
            </div> -->
            <!-- <div data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Articles" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Content' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-solid fa-book-open-reader text-sm"></i>
              </router-link>
            </div> -->
            <!-- <a href="javascript:void(0)" class="list-group-item list-group-item-action bg-transparent py-2 border-0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Chat">
              <i class="fa-solid fa-message-lines text-sm"></i>
            </a> -->
            <div v-if="user_type !== 'agent' && showContactForm" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Contact Form" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'ContactForm' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-solid fa-envelope-open-text text-sm"></i>
              </router-link>
            </div>
            <div v-if="$store.state?.userPermissions.canCreateAndUpdateNewsArticles" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="News Flash" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'NewsFlash' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-solid fa-newspaper text-sm"></i>
              </router-link>
            </div>
            <div v-if="user_type !== 'agent'" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Statistics" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Statistics' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <i class="fa-solid fa-chart-simple text-sm"></i>
              </router-link>
            </div>
            <div v-if="user_type !== 'agent'" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Integrations" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Integrations' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <!-- <i class="fa-solid fa-chart-simple text-sm"></i> -->
                <i class="fa-solid fa-square-share-nodes text-sm"></i>
              </router-link>
            </div>
            <div v-if="$store.state.userPermissions.canCreateAndUpdateSopArticles" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="SOPs" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Sop' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <!-- <i class="fa-solid fa-chart-simple text-sm"></i> -->
                <i class="fa-solid fa-tasks text-sm"></i>
              </router-link>
            </div>
            <div v-if="$store.state.userPermissions.canCreateAndUpdateCoursesArticles" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Courses" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Courses' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <!-- <i class="fa-solid fa-chart-simple text-sm"></i> -->
                <i class="fa-solid fa-book-copy text-sm"></i>
                
              </router-link>
            </div>
            <div v-if="$store.state.userPermissions?.canCreateAndEditAnAutomation" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Automation" @click="handleRouterLinkClick">
              <router-link :to="{ name: 'Automation' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0" :class="[darkModes]">
                <!-- <i class="fa-solid fa-chart-simple text-sm"></i> -->
                <!-- <i class="fa-solid fa-tasks text-sm"></i> -->
                <i class="fa-solid fa-bolt text-sm"></i>
              </router-link>
            </div>
          </div>
        </div>
        <!-- The last 3 icon links that should stay at the bottom -->
        <div class="mt-auto list-group nav-icons">
          <!-- <a v-if="user_type !== 'agent'" href="javascript:void(0)" class="list-group-item list-group-item-action bg-transparent py-2 border-0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Help" @click="showAhw = !showAhw">
            <div id="admin-help-widget" class="admin-help-widget positon-absolute bottom right">
              <i v-if="!showAhw" class="fa-solid fa-circle-question fa-2x"></i>
              <i v-else class="fa-sharp fa-solid fa-circle-chevron-up fa-2x"></i>
            </div>
          </a> -->
          <!-- <a v-if="user_type !== 'agent'" href="javascript:void(0)" class="list-group-item list-group-item-action bg-transparent py-2 border-0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Preview SOP" @click="showSop = !showSop">
            <i class="fa-regular fa-clipboard-list-check fa-2x"></i>
          </a>
          <a v-if="user_type !== 'agent'" href="javascript:void(0)" class="list-group-item list-group-item-action bg-transparent py-2 border-0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Preview" @click="showWidget = !showWidget">
            <i class="fa-solid fa-eye text-sm"></i>
          </a> -->

          <!-- <div v-if="user_type !== 'agent'" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Settings" @click="handleRouterLinkClick">
            <router-link :to="{ name: 'Settings' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0">
              <i class="fa-solid fa-gear text-sm"></i>
            </router-link>
          </div> -->

          <!-- <a href="javascript:void(0)" class="list-group-item list-group-item-action bg-transparent py-2 border-0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Sign Out" @click="logout_user">
            <i class="fa-solid fa-right-from-bracket text-sm"></i>
          </a> -->
            <!-- data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title=""  -->
             <!-- @click="handleRouterLinkClick" -->
          <div class="position-relative profile-details-wrap">
          <Popper v-bind="$attrs" hover>
            <router-link :to="{ name: 'Account' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0 mb-2 profile-icon">
                <div class="profile-image-container">
                    <img v-if="$store.state.agent_picture" :src="$store.state.agent_picture" alt="" class="profile-image" style="object-fit: cover;">
                    <!-- <i v-else class="text-lg fa-solid fa-circle-user fa-2x"></i> -->
                    <i v-else class="fa-duotone fa-circle-user fa-2x text-lg profile-default-ico"> </i>
                </div>
                
                <div class="fa-2x me-2 profile-default-ico profile-default-ico-nav"> <span class="online-status" :class="{ 'offline-status': awayMode }"></span></div>
            </router-link>



            <!-- <router-link :to="{ name: 'Account' }" class="list-group-item list-group-item-action bg-transparent py-2 border-0 mb-2 profile-icon">
              <i class="text-lg fa-solid fa-circle-user text-lg shadow rounded"></i>
              <img v-if="$store.state.agent_picture" :src="$store.state.agent_picture" alt="">
              <i v-else class="text-lg fa-solid fa-circle-user fa-2x"></i>
              <div class="cursor-pointer">
                    <img  :src="require('@/assets/img/userprofile-new.jpg')" class="rounded-1" alt="user">
                </div>
            </router-link> -->

             <template #content="{ close }">
                <div  class="popover-content bg-white rounded-3 shadow inbox-nav-manage-options" >
                  <div class="col-12 container-fluid p-0">
                    <div class="profile-details">
                      <div class="card rounded-3 border p-0">
                        <ul class="list-unstyled text-sm">
                          <div class="card-header p-2  border-bottom border-light">
                            <li class="py-1 d-flex align-items-center justify-content-start" :class="{ active: $route.name === 'Account' }" >
                              <div class="d-flex align-items-center position-relative">
                            
                                <div class="profile-image-container">
                                    <img v-if="$store.state.agent_picture" :src="$store.state.agent_picture" alt="" class="profile-image" style="object-fit: cover;" >
                                    <i v-else class="fa-duotone fa-circle-user fa-2x profile-default-ico"> </i>
                                </div>            
                                <!-- <i class="fa-duotone fa-circle-user fa-2x me-2 profile-default-ico"> <span 
                                  class="online-status" :class="{ 'offline-status': awayMode }"></span></i> -->
                                  <div class="fa-2x me-2 profile-default-ico"> <span class="online-status" :class="{ 'offline-status': awayMode }"></span></div>                                
                              </div>
                              <div class="text-start" :class="[$store.state.agent_picture ? 'mx-1' : 'mx-2']">
                                <div>{{$store.state.agent_name || 'Agent'}}</div>
                                <div class="text-xs"  style="white-space: nowrap; width:150px; overflow: hidden;text-overflow: ellipsis;">{{ user_email }}
                                </div>
                              </div>
                            </li>
                            <li class="py-1  d-flex align-items-center justify-content-between" :class="{ active: $route.name === 'Account' }">
                              <div class="d-flex align-items-center">Away mode </div>
                              <div class="d-flex align-items-center p-0">
                                <div class="form-check form-switch">
                                  <input class="form-check-input m-0" type="checkbox" :checked="$store.state.is_away == 1" @change="changeStatus($event)">
                                </div>
                              </div>
                            </li>
                          </div>
                          <div class="p-2 py-1  border-bottom border-light position-relative">
                            <li class="theme-dark-light d-flex align-items-center justify-content-between">
                              <div class="d-flex align-items-center">Theme 
                                <div class="d-flex align-items-center justify-content-start mx-0" v-if="themeLight">
                                  <span class="d-flex align-items-center justify-content-start me-2"><i class="fa-solid fa-moon-stars p-1 mx-2"></i></span>
                                </div>
                                <div class="d-flex align-items-center justify-content-start mx-0" v-else>
                                  <span class="d-flex align-items-center justify-content-start"><i class="fa-solid fa-sun-bright p-1 mx-2"></i></span>
                                </div>
                              </div>
                              <div class="d-flex align-items-center">
                                <div class="form-check form-switch theme-switch"><input class="form-check-input m-0" type="checkbox" v-model="themeLight" @change="toggleModalTheme"></div>
                              </div>
                            </li>                
                          </div>
                          <div class="p-2  border-bottom border-light position-relative" @click="close">
                            <li class="sub-menu-language py-1  d-flex align-items-center justify-content-between cursor-pointer text-sm">
                              <div class="d-flex align-items-center">
                                <span><strong>Language</strong>: English (US)</span>
                              </div>
                              <div class="d-flex align-items-center">
                              <span><i class="fa-regular fa-chevron-right" style="font-weight: 800; font-size: 11px;"></i></span>
                              </div>
                                <div class="p-3 py-0 profile-details-language d-none">
                                  <div class="card rounded-3 border p-0">
                                    <ul class="list-unstyled text-sm pb-0" style="margin-bottom: 0.5rem !important;">
                                      <div class="card-header p-2">
                                        <li class="py-1  d-flex align-items-center justify-content-between cursor-pointer" @click="close">
                                          <div class="d-flex align-items-center">
                                            <span class="text-sm"><strong>English (US)</strong> </span>
                                          </div>
                                          <div class="d-flex align-items-center">
                                          <span><i class="fa-solid fa-check text-success"></i></span>
                                          </div>
                                        </li>
                                        <li class="py-1  d-flex align-items-center justify-content-between cursor-pointer" @click="close">
                                          <div class="d-flex align-items-center">
                                            <span>Deutsch (DE) </span>
                                          </div>
                                          <div class="d-flex align-items-center">
                                          <!-- <span><i class="fa-solid fa-check"></i></span> -->
                                          </div>
                                        </li>   
                                        <!-- <li class="py-1  d-flex align-items-center justify-content-between cursor-pointer" @click="close">
                                          <div class="d-flex align-items-center">
                                            <span>Française (FR) </span>
                                          </div>
                                          <div class="d-flex align-items-center">
                                          <span><i class="fa-solid fa-check"></i></span>
                                          </div>
                                        </li>    -->
                                        <li class="py-1  d-flex align-items-center justify-content-between cursor-pointer" @click="close">
                                          <div class="d-flex align-items-center">
                                            <span>Spanish (ES) </span>
                                          </div>
                                          <div class="d-flex align-items-center">
                                          <!-- <span><i class="fa-solid fa-check"></i></span> -->
                                          </div>
                                        </li>   
                                        <!-- <li class="py-1  d-flex align-items-center justify-content-between cursor-pointer" @click="close">
                                          <div class="d-flex align-items-center">
                                            <span>Portugues (BR) </span>
                                          </div>
                                          <div class="d-flex align-items-center">
                                          <span><i class="fa-solid fa-check"></i></span>
                                          </div>
                                        </li>     -->
                                      </div>
                                    </ul>
                                  </div>                  
                                </div>                            
                            </li>
                                                
                          </div>
                          <div class="card-body border-bottom border-light p-0 py-2" @click="handleRouterLinkClick">
                            <div class="" @click="close">
                            <li class="sub-menu-language py-1  d-flex align-items-center justify-content-between cursor-pointer text-sm" @click="close" v-if="user_type !== 'agent'">
                              <router-link :to="{ name: 'Settings' }" class="list-group-item list-group-item-action bg-transparent border-0 p-0 text-sm">
                              <div class="d-flex align-items-center text-sm" >
                                <i class="p-2 py-0 text-sm fa-solid fa-cog me-2"></i>                              
                                 <span class="text-sm" style="font-size: 0.95em !important;">Settings</span>
                              </div>
                              </router-link>
                            </li>
                            </div>
                             <div class="" @click="close">
                            <li class="sub-menu-language py-1  d-flex align-items-center justify-content-between cursor-pointer">
                              <router-link :to="{ name: 'Account' }" class="list-group-item list-group-item-action bg-transparent border-0 p-0 mb-0 text-sm">
                              <div class="d-flex align-items-center text-sm">
                                <i class="p-2 py-0 text-sm fa-solid fa-user me-2"></i>
                                <span class="text-sm" style="font-size: 0.95em !important;">Your Account</span>
                              </div>
                              </router-link>                      
                            </li>
                            </div>
                            <!-- <li class="sub-menu-language py-1  d-flex align-items-center justify-content-between cursor-pointer" @click="close">
                              <div class="d-flex align-items-center">
                                <i class="p-2 py-0 text-sm fa-solid fa-bell-on me-2" style="margin-left: -4px;"></i>
                                Notifications
                              </div>
                            </li> -->
                          </div>
                          <div class="card-footer p-0 m-0 pt-2" @click="logout_user">
                            <div class="" @click="close">
                              <li class="sub-menu-language py-1 pb-0 m-0  d-flex align-items-center justify-content-between cursor-pointer">
                                <div class="d-flex align-items-center">
                                  <i class="p-2 py-0 text-sm fa-solid fa-right-from-bracket me-2"></i>
                                  Log Out
                                </div>
                              </li>
                            </div>
                          </div>                      
                        </ul>
                      </div>                  
                    </div>  
                  </div>
                </div>  
                
              </template> 
              </popper>  
             </div>
            </div>
      </div>
        
      <!-- End of New Main Navigation -->
    </div>
  </div>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg top-0 bg-gradient-dark header position-fixed d-none" :class="isBlur ? isBlur : 'shadow-none mb-0 navbar-transparent w-100'">
    <div class="container ps-2 pe-0">
      <router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3" :class="darkMode ? 'text-black' : 'text-white'" to="/"><img src="@/assets/img/modal_logo.png" alt="" width="100" /> </router-link>
      <button class="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="isExpanded = !isExpanded">
        <i class="fa fa-bars"></i>
      </button>
      <div id="navbarSupportedContent" class="pt-3 pb-2 collapse navbar-collapse w-100 py-lg-0">
        <ul class="mx-auto navbar-nav navbar-nav-hover">
          <li class="mx-2 d-flex nav-item dropdown dropdown-hover">
            <router-link :to="{ name: 'Content' }" class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center" :class="[darkModes]">
              <i class="fa fa-home" aria-hidden="true"></i>
            </router-link>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <router-link :to="{ name: 'Content' }" class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center" :class="[darkModes]"> Content </router-link>
          </li>
          <!-- <li class="mx-2 nav-item dropdown dropdown-hover">
            <router-link
              :to="{ name: 'CreateArticle' }"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
              :class="[darkModes]"
            >
            Create Article
            </router-link>            
          </li> -->
          <!-- <li class="mx-2 nav-item dropdown dropdown-hover">
            <router-link
              :to="{ name: 'Contact' }"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
              :class="[darkModes]"
            >
            Contact Us
            </router-link>            
          </li> -->
          <!-- <li class="mx-2 nav-item dropdown dropdown-hover">
            <router-link
              :to="{ name: 'Setup' }"
              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
              :class="[darkModes]"
            >
            Settings
            </router-link>            
          </li> -->
          <li class="mx-2 nav-item dropdown dropdown-hover" v-if="showContactForm">
            <router-link :to="{ name: 'ContactForm' }" class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center" :class="[darkModes]"> Contact Form </router-link>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover" v-if="showContactForm">
            <router-link :to="{ name: 'NewsFlash' }" class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center" :class="[darkModes]"> NewsFlash </router-link>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">
            <router-link :to="{ name: 'Statistics' }" class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center" :class="[darkModes]"> Statistics </router-link>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover d-lg-none d-block">
            <a class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-centerd" @click="logout_user">Logout</a>
          </li>
        </ul>
        <ul class="navbar-nav d-lg-block">
          <li class="nav-item dropdown">
            <a href="#" class="p-lg-0 nav-link" :class="`${isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white1'}  ${showMenu ? 'show' : ''}`" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="d-sm-inline d-block">
                {{ user_support_library_details?.library_domain !== 'undefined' ? user_support_library_details?.library_domain : user_support_library_details?.library_email ? user_support_library_details?.library_email : businessName }} &nbsp;<i class="fa fa-caret-down"></i>
                <!-- {{ user.email }} -->
                <!-- {{ user_info.support_lib_id }} -->
              </div>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end bg-white pt-0" aria-labelledby="dropdownMenuButton1">
              <li class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item">
                <router-link :to="{ name: 'Settings' }" class="py-2 dropdown-item ps-3 border-radius-md">Settings </router-link>
              </li>
              <li class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item">
                <a href="#" class="py-2 dropdown-item ps-3 border-radius-md" @click="showWidget = !showWidget">Preview </a>
              </li>
              <li>
                <a class="py-2 dropdown-item ps-3 border-radius-md" @click="logout_user">Logout</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div v-if="showWidget" class="position-fixed w-50 widget-preview-wrapper shadow">
    <span class="bg-dark cursor-pointer text-white rounded-circle d-flex justify-content-center align-items-center position-absolute close-widget-btn" v-on:click="showWidget = !showWidget">
      <i class="fa fa-times fw-bold"></i>
    </span>
    <iframe id="modal_support_frame" :data-app_url="getLocation()" :src="'https://app.modalsupport.com/widget/' + support_lib_id" class="widget" frameborder="0"></iframe>
  </div>

  <div v-if="showSop" class="position-fixed w-25 widget-preview-wrapper shadow bg-gray-light sop-preview-pannel ">
    <span class="bg-dark cursor-pointer text-white rounded-circle d-flex justify-content-center align-items-center position-absolute close-widget-btn sop-btnclose" v-on:click="showSop = !showSop">
      <i class="fa fa-times fw-bold"></i>
    </span>
    <PreviewSop />
  </div>
  <div 
    ref="draggable" 
    @click="handleClick" 
    class="admin-help-widget-br" 
    @mousedown="startDrag"
    :style="draggableStyle"
  >
    <div class="p-0">
      <i :class="iconClass"></i>
    </div>
  </div>
  <div 
    :class="{ 'ahw-preview-pannel': true, 'open': showAhw }" 
    ref="previewPanel"
    :style="panelStyle"
  >
    <PreviewAhw :showAhw="showAhw" />
  </div>
   
  <!-- <div :class="{ 'ahw-preview-pannel': true, 'open': showAhw }"> -->
      <!-- <span class="bg-white cursor-pointer rounded-circle d-flex justify-content-center align-items-center position-absolute close-widget-btn ahw-btnclose" v-on:click="showAhw = !showAhw">
        <i class="fa-light fa-xmark-large opacity-3"></i>
      </span> -->
      <!-- <PreviewAhw :showAhw="showAhw" />
  </div> -->
  <!-- <draggable >
    <div class="admin-help-widget-br" @click="showAhw = !showAhw">
      <div class="p-0">
        <i v-if="!showAhw" class="fa-solid fa-circle-question fa-2x"></i>
        <i v-else class="fa-sharp fa-solid fa-circle-chevron-up fa-2x"></i>
      </div>
    </div>
  </draggable> -->
  <!-- End Navbar -->
</template>

<script>
import { ref } from 'vue';
import getUser from '@/composables/getUser';
import useLogout from '@/composables/useLogout';
import getUserInfo from '@/composables/getUserInfo';
import { useRouter } from 'vue-router';
/* import { ref } from 'vue' */
//const { user } = getUser()
import downArrWhite from '@/assets/img/down-arrow-white.svg';
import downArrBlack from '@/assets/img/down-arrow-dark.svg';
import { projectFirestore } from '../../../firebase/config';
import moment from 'moment';
import '@/assets/custom_global_chatinbox.css';
import '@/assets/custom_modalchatui.css';
import PreviewSop from '@/views/admin/Sop/PreviewSop';
import PreviewAhw from '@/views/admin/Ahw/PreviewAhw';
import { VueDraggableNext } from 'vue-draggable-next'
export default {
  name: 'NavbarAdmin',
  components: {
    PreviewSop,
    PreviewAhw,
    'draggable': VueDraggableNext,
  },
  setup() {
    /* const user = ref('') */
    const { user, user_support_lib_id } = getUser();
    const router = useRouter();
    const { logout, error } = useLogout();
    const support_lib_id = ref('');
    const { user_info_error, user_info, load_user_info } = getUserInfo(user.value.uid);

    return {
      user,
      user_info_error,
      user_info,
      load_user_info,
      support_lib_id,

      logout,
    };
  },

  props: {
    btnBackground: {
      type: String,
      default: '',
    },
    isBlur: {
      type: String,
      default: '',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    isNavFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user_email:localStorage.getItem('user_email'),
      downArrWhite,
      downArrBlack,
      showMenu: false,
      isExpanded: false,
      site_url: '',
      showContactForm: true,
      user_support_library_details: null,
      support_lib_error: '',
      businessName: '',
      showWidget: false,
      showSop: false,
      isMenuCollapsed: false,
      user_type: localStorage.getItem('user_type'),
      awayMode: false,
      themeLight: false,
      /* drag */
      showAhw: false,
      offsetX: 0,
      offsetY: 0,
      isDragging: false,
      draggableStyle: {
        position: 'absolute',
        cursor: 'pointer',
      },
      panelStyle: {
        position: 'absolute',
        right: 'initial',
        bottom: 'initial',
      },
      allowClick: true,
    };
  },
  mounted() {
    console.log('!!this.$store.state.is_away', !!this.$store.state.is_away)
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
    document.addEventListener('click', this.handleGlobalClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleGlobalClick);
  },
  methods: {
    handleClick() {
      if (this.allowClick) {
        this.showAhw = !this.showAhw;
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.offsetX = event.clientX - this.$refs.draggable.getBoundingClientRect().left;
      this.offsetY = event.clientY - this.$refs.draggable.getBoundingClientRect().top;
      this.draggableStyle.cursor = 'grabbing';

      // Add event listeners for mouse move and up events
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.endDrag);
      event.preventDefault();
    },
    drag(event) {
      if (this.isDragging) {
        event.preventDefault();
        requestAnimationFrame(() => {
          // Update icon position
          this.draggableStyle.left = `${event.clientX - this.offsetX}px`;
          this.draggableStyle.top = `${event.clientY - this.offsetY}px`;

          // Update panel position if open
          if (this.showAhw) {
            const draggableRect = this.$refs.draggable.getBoundingClientRect();
            this.panelStyle.right = `${window.innerWidth - draggableRect.right}px`;
            this.panelStyle.bottom = `${window.innerHeight - draggableRect.top}px`;
          }
        });
      }
    },
    endDrag() {
      this.isDragging = false;
      this.draggableStyle.cursor = 'pointer';

      // Remove event listeners for mouse move and up events
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.endDrag);

      // Update the position of the preview panel relative to the draggable element
      const draggableRect = this.$refs.draggable.getBoundingClientRect();
      this.panelStyle.right = `${window.innerWidth - draggableRect.right}px`;
      this.panelStyle.bottom = `${window.innerHeight - draggableRect.top}px`;
    },

  
    changeStatus(event) {
      console.log('event ', event.target.checked)
      let is_away = event.target.checked ? 1 : 0
      this.awayMode = event.target.checked
      this.$store.state.is_away = is_away;
      let payload = {
        action:'updateAgentInfo',
        agent_action:'switch_away_mode',
        ref_uid:localStorage.getItem('user_id'),
        away_mode:is_away,
        support_lib_id:localStorage.getItem('support_Lib_id')
      }
      console.log('switch_away_mode :- ', payload);
      console.log('this.$store.state.socket', this.$store.state.socket)
      this.$store.state.socket.send(JSON.stringify(payload));
    }, 
    // toggleAdminHelpWidget() {
    //   this.showAhw = !this.showAhw; 
    // },
     toggleModalTheme() {
      const head = document.head;
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = this.themeLight ? '/darkmodalui.css' : '';
      const existingLink = document.getElementById('modaldarkui');
      if (existingLink) {
        head.removeChild(existingLink);
      }
      if (this.themeLight) {
        link.id = 'modaldarkui';
        head.appendChild(link);
      }
      localStorage.setItem('themeLight', JSON.stringify(this.themeLight));
    },
    handleGlobalClick(event) {
      const tooltip = document.querySelector('.tooltip');
      if (tooltip && event.target !== tooltip) {
        tooltip.remove();
      }
    },
    async logout_user() {
      await this.logout();
      if (this.$store.state.socket) {
        this.$store.state.socket.close(1000, 'Logout');
      }
    },
    async getSupportLibrary(support_lib_id) {
      try {
        if (support_lib_id) {
          let res = await projectFirestore.collection('SupportLibraries').doc(support_lib_id).get();
          if (!res.exists) {
            throw error('That user support library does not exist');
          }
          if (res.data().settings && res.data().settings.settings.companyName) {
            this.businessName = res.data().settings.settings.companyName;
          }
          this.user_support_library_details = { ...res.data(), id: res.id };
          // console.log('{ ...res.data(), id: res.id }', support_lib_id)
        }
      } catch (err) {
        this.support_lib_error = err.message;
      }
    },
    getLocation() {
      return window.location != window.parent.location ? document.referrer : document.location.href;
    },
    handleRouterLinkClick() {
      const tooltip = document.querySelector('.tooltip');
      if (tooltip) {
        tooltip.remove();
      }
      if (window.location.href.endsWith('/login')) {
        if (tooltip) {
          tooltip.remove();
        }
      }
    },
    toggleMenuCollapse() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
  },
  
  created() {
    const storedThemeLight = localStorage.getItem('themeLight');
    if (storedThemeLight !== null) {
      this.themeLight = JSON.parse(storedThemeLight); 
    }
    this.toggleModalTheme(); 
  },

  beforeRouteEnter(to, from, next) {
    const tooltip = document.querySelector('.tooltip');
    if (tooltip) {
      tooltip.remove();
    }
    next();
  },
  watch: {
    '$store.state.is_away':{
      deep:true,
      handler() {
        this.awayMode = this.$store.state.is_away == 0 ? false : true
        this.$store.state.show_alert = this.$store.state.is_away == 0 ? false : true
      }
    },
    support_lib_id: async function () {
      if (!this.support_lib_id) {
        this.logout_user();
      }
    },
    $route() {
      document.getElementById('navbarSupportedContent').classList.remove('show');
    },
  },
  async mounted() {
    await this.load_user_info(this.user.uid);
    if (this.user_info && this.user_info.support_lib_id !== undefined) {
      this.support_lib_id = this.user_info.support_lib_id;
    } else {
      this.logout();
    }

    await this.getSupportLibrary(this.support_lib_id);

    this.site_url = 'https://modalsupport.com/' + this.support_lib_id;

    projectFirestore
      .collection('SupportLibraries')
      .doc(this.support_lib_id)
      .get()
      .then(doc => {
        if (doc.exists) {
          if (doc.data().publicHelpCenterTheme && doc.data().publicHelpCenterTheme.favicon) {
            const favicon = document.getElementById('favicon');
            favicon.setAttribute('href', doc.data().publicHelpCenterTheme.favicon);
          } else {
            favicon.setAttribute('href', '/favicon.ico');
          }
        }
      });
  },
  computed: {
    iconClass() {
      return this.showAhw ? 'fa-sharp fa-solid fa-circle-chevron-up fa-2x' : 'fa-solid fa-circle-question fa-2x';
    },
    darkModes() {
      return {
        'text-dark': this.darkMode,
      };
    },
  },
};
</script>
<style scoped>
.header {
  z-index: 999;
}

.widget-preview-wrapper {
  top: 0;
  z-index: 9999;
  right: 0;
  width: 100%;
  height: 100%;
}

.widget {
  width: 100%;
  height: 100%;
}

.close-widget-btn {
  right: 25px;
  top: 25px;
  width: 35px !important;
  height: 35px !important;
  z-index: 999;
}

.sop-btnclose {
   right: 5px !important;
  top: 10px !important;
  width: 25px !important;
  height: 25px !important;
  z-index: 999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01); /* Semi-transparent black */
}
.modal-chat-inbox .nav-icons a i.fa-clipboard-list-check.fa-2x {
  font-size: 18px;
}
.profile-icon{
  padding: 5px 10px 5px 10px !important;
}
.plus.unread-conversations {
   /* hundred-plus */
   letter-spacing: -0.07rem;
   max-width: 14px;
   max-height: 14px;
   width: 14px;
   height: 14px;
   right: 6px

}

.plus.unread-conversations.hundred-plus:after {
   content: "+";
   position: absolute;
   top: -10px;
   right: -3px;
   color: #000;
}
.profile-image-container {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden; 
    display: flex; 
    justify-content: center;
    align-items: center; 
}

.profile-image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
}

.admin-help-widget-br {
  width: 50px;
  height: 50px; 
}
.admin-help-widget-br i::before{
  background-color: white;
  border-radius: 50%;
}
</style>
